.ticketing-reports {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  &--select__control {
    background-color: #f8f8f8 !important;
  }
  .reports-modal-footer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reports-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-top: 10px;
      a {
        text-decoration: underline;
      }
    }
  }

  .reports-loading-text {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  .btn-primary {
    margin-top: 20px;
  }
}
