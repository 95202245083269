// ================================================================================================
//  File Name: custom-rtl.scss
//  Description: RTL support SCSS file.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Variables
// ------------------------------

@import "bootstrap-extended/include"; // Bootstrap includes
@import "components/include"; // Components includes
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

// .css-g1d714-ValueContainer {
//   background-color: transparent !important;
//   border: none !important;
// }

// .edit_profile_dropdown {
//   left: -60px !important;
// }
// .vertical-layout.vertical-layout.vertical-menu-modern.menu-expanded {
//   background-color: crimson !important;
// }

html[dir="rtl"] {
  .pagination-actions__navigation {
    direction: ltr;
  }
}


.max-100-width {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu {
  border-radius: 16px !important;
  box-shadow: none;
}

.event-none {
  pointer-events: none;
}

.breadcrumb-wrapper {
  .breadcrumb {
    padding: 0px;

    &:not([class*='breadcrumb-']) {
      .breadcrumb-item+.breadcrumb-item {
        &:before {
          content: '/';
          background-image: none;
          margin-right: 0px;
          color: #CDD0D5;
          padding: 0px;
          padding-right: 5px;

        }
      }
    }

    .breadcrumb-item {
      padding: 0px;
      color: #CDD0D5;
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 30px;

      &:not(:first-child) {
        padding-left: 5px;
      }

      &:last-child {

        color: #525866;
      }
    }
  }
}

.rtl-toggler {
  margin-right: 30px;

  .app-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.ticket-actions-dropdown {
  padding: 0px;
  border: none;
  box-shadow: none !important;
  border-radius: 8px;


  .app-btn {
    margin: 0;

    .app-icon {
      svg {
        path {
          fill: #0A0D14;
        }
      }
    }
  }
}

.notification-dropdown {
  margin-right: 30px;

  &--container {
    position: relative;

    .app-icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .badge {
      z-index: 1;
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #EA5455 !important;
      color: #FFF;
      font-family: "Inter", sans-serif;
      font-size: 11px;
      font-weight: 600;
      line-height: 14px;
      padding: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -2px;
      top: 0px;
    }
  }
}

.userinfo-dropdown {
  margin-right: 20px;
  border-right: 1px solid #CDD0D5;
  padding-right: 20px;

  &--dropdown-toggle {
    display: flex;
    align-items: center;

    &.btn,
    &.btn-secondary {
      padding: 0;
      border: none;
      background-color: transparent !important;

      &:hover {
        box-shadow: none !important;
      }
    }

    color: var(--text-main-900, #0A0D14);
    font-family: "Inter",
    sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

  }

  &--avatar {
    margin-right: 20px;

    .avatar {
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 38px;

      .avatar-content {}
    }
  }

  &--text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 0;
      line-height: 1;
      font-family: "Inter", sans-serif;
    }

    .user-name {
      color: #4F4F4F;
      font-size: 12px;
      margin-bottom: 5px !important;
    }

    .user-role {
      color: #828282;
      font-size: 10px;
    }
  }

  .dropdown-menu {
    top: 50px !important;
    margin-right: 0px;
  }

}

.create-page-dropdown {
  .btn {
    padding: 0;
    border-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;

    &:hover {
      box-shadow: none;
    }
  }

  .app-btn {
    background-color: $secondary;
    border-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border: none !important;
    box-shadow: none;
    width: 121px;
    height: 40px;

    .text {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #FFF;
      line-height: 20px;
    }

    .app-icon {
      svg {
        width: 20px;
        height: 20px;

        path {
          fill: #fff;
        }
      }
    }
  }

  .dropdown-menu {
    top: 50px !important;
    margin-right: 0px;
    width: 230px !important;

    .dropdown-item {
      display: flex;
      align-items: center;
      color: var(--text-main-900, #0A0D14) !important;

      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      .app-icon {
        margin-right: 8px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

}

.customSelect {
  display: flex;
  align-items: center;
  // border: 1px solid #D9D9D9;
  // background: #FFF;
  // padding: 10px;
  // padding-bottom: 0px;
  // border-radius: 5px;
  // min-height:30px
}

.customSelect_1 {
  display: flex;
  align-items: center;
  // border: 1px solid #D9D9D9;
  // background: #FFF;
  // padding: 10px;
  // padding-bottom: 0px;
  // border-radius: 5px;
  // min-height:30px
}

.customSelect_2 {
  display: flex;
  align-items: center;
  // border: 1px solid #D9D9D9;
  // background: #FFF;
  // padding: 10px;
  // padding-bottom: 0px;
  // border-radius: 5px;
  // min-height:30px
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  /* Adjust as needed */
  margin-bottom: 20px;
}

.gridItem {
  // background-color: #ddd; /* Add your own styles */

  // padding: 20px;
}

.operation-center-form-switch {
  .custom-control {
    z-index: 0;
  }
}

// }
//tag style start
.content-custom {
  padding: 140px 50px 0 50px;
}

.fr-sticky-on+.fr-sticky-dummy {
  display: none !important;
}

.ticket-delete-dev-image {
  margin-top: 100px;
}

.ticket-delete-image {
  width: 35%;
}

.row-ticketdel {
  margin-top: 40px;
}

.main-head-tickedel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 59px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #1a355e;
}

.sub-head-ticketdel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  /* identical to box height, or 135% */

  text-align: center;

  color: #000000;
}

.button-ticketdel {
  margin-top: 20px;
  background-color: $secondary;
  border: 1px solid $secondary;
  color: white;
}

.info-icon-role {
  cursor: pointer;
  width: 16px;
  margin-left: 4px;
}

.spinner-action {
  color: #1a355e !important;
}

.main-div-under {
  z-index: 0 !important;
}

.under-button {
  background-color: #00a0df !important;
  border-color: #00a0df !important;
  // padding: 15px 10px !important;
}

.add-permission {
  background-color: transparent !important;
  border: 1px solid hsla(0, 0%, 53.3%, 0.5) !important;
  box-shadow: none !important;
  // padding: 15px 10px !important;
}

.add-permission:active {
  background-color: transparent !important;
  border: 1px solid hsla(0, 0%, 53.3%, 0.5) !important;
  box-shadow: none !important;
  // padding: 15px 10px !important;
}

.error-code-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  // line-height: 34px;
  /* identical to box height, or 135% */

  text-align: center;

  color: #000000;
}

.maintenance-image {
  margin-top: 100px !important;
}

.error-code {
  padding-top: 90px !important;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 59px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #1a355e;
}

.forbid-col-2 {
  margin-top: 70px;
}

.main-head-forbid-s {
  margin-top: 60px;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 34px;
  /* identical to box height, or 67% */

  text-align: center;
  text-transform: uppercase;

  color: #1a355e;
}

.main-head-forbid2 {
  margin-top: 60px;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 34px;
  /* identical to box height, or 67% */

  text-align: center;
  text-transform: uppercase;

  color: #eb5757;
}

.main-head-forbid {
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 75px;
  line-height: 34px;
  /* identical to box height, or 40% */

  text-align: center;
  text-transform: uppercase;

  color: #eb5757;
}

.row-forbid {
  margin-top: 100px;
}

.sub-head-forbid {
  margin-top: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  /* or 135% */

  text-align: center;

  color: #000000;
}

.button-forbid {
  margin-top: 50px;
  background-color: $secondary;
  border: 1px solid $secondary;
  color: white;
}

.escalation-icon {
  margin-right: 5px !important;
}

.react-tags {
  position: relative;
  padding: 5px 0 0 5px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 6px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  // width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    // width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

//end
.jodit-status-bar {
  display: none !important;
}

.jodit-workplace+.jodit-status-bar:not(:empty) {
  display: none;
}

.status-all {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

html[dir="rtl"] {
  body {
    .apexcharts-yaxis-texts-g .apexcharts-xaxis-inversed-texts-g {
      direction: ltr;
      background: blue !important;
    }
  }
}

.pagination-wnd {
  justify-content: end !important;
}

.dropdown-menu-w1 {
  min-width: 16rem !important;
}

.dropdown-menu-w2 {
  min-width: 12rem !important;
}

[dir] .main-menu ul.navigation-main {
  // height: 870px !important;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  margin-top: 5px;
}

.search-input {
  width: 92%;
}

.notifications__sideBar {
  background: #e5e5e5;
  color: #333333;
  cursor: pointer;

  // width: 35rem !important;
  .modal-header,
  .modal-body {
    background-color: #e5e5e5 !important;
  }

  .modal-header {
    // padding-top: 2rem;
    align-items: center;

    .modal-title {
      font-weight: 600;
      font-size: 2rem;
      font-style: normal;
      color: inherit;
    }

    svg {
      color: inherit;
      width: 2.5rem;
      height: 2.5rem;
      opacity: 0.5;
      transition: all 0.35s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-body {
    .nav-tabs .nav-link {
      // padding-bottom: 1.61rem;
      font-weight: 400;
      letter-spacing: 1px;
      color: #6e7191;

      &.active {
        color: #1a355e;
      }
    }

    .custom-switch .custom-control-label {
      &::before {
        background: #ffffff;
      }

      &::after {
        background: #e5e5e5;
      }
    }
  }

  .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #1a355e;
  }

  .custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #1a355e;
  }

  &__notificationContainer {
    background: #ffffff;
    display: block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 5px;
    padding: 1rem;

    transform: scale(0.98, 0.98);

    &:hover {
      transform: scale(1, 1);
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &__main {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &--avatar {
        width: 4rem;
        height: 4rem;
        border-radius: 15px;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .avatar-content {
          font-size: 2rem;
        }
      }

      &__body {

        &--title,
        &--subTitle,
        &--org {
          margin: 0;
        }

        &--title {
          color: #333333;
          letter-spacing: 0.25px;
          font-family: Poppins;
          font-size: 1.1rem;
          font-weight: 500;
        }

        &--subTitle {
          color: #6e7191;
          font-size: 1rem;

          b {
            font-weight: 400;
          }
        }
      }
    }

    &--time {
      margin-top: 0.5rem;
      color: #aebfc2;
      display: block;
      text-align: right;
      font-size: 0.8rem;
    }
  }
}

.customWidth {
  width: 200px;
}

.profile-dropdown {
  left: -85 !important;
}

.all-tickets-badge {
  z-index: 9999 !important;
  margin-right: 4px !important;
  border-radius: 20px !important;
}

.allhigh {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-transform: uppercase;

  color: #c11c15;
}

.alllow {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-transform: uppercase;

  color: #41b376;
}

.allcritical {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-transform: uppercase;

  color: #f2482a;
}

.allmedium {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-transform: uppercase;

  color: #199ddf;
}

.allclosed {
  color: #eb5757;
  text-transform: capitalize;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
}

.allopened {
  color: #f2994a;
  text-transform: capitalize;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
}

.returnTo {
  color: $secondary;
  text-transform: capitalize;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
}

.allreopened {
  color: #7879f1;
  text-transform: capitalize;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
}

.allsolved {
  color: #27ae60;
  text-transform: capitalize;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
}

.allticket-last-activity-text {
  font-family: Poppins;
  background-color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  /* Gray 2 */

  color: #4f4f4f;
}

.allticket-subject-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* Gray 2 */

  color: #4f4f4f;
}

.allticket-ticket-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* Gray 2 */

  color: #4f4f4f;
}

.allticket-region-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  /* Gray 2 */

  color: #4f4f4f;
}

.allticket-team-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  /* Gray 2 */

  color: #4f4f4f;
}

.allticket-due-date-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  text-align: center;

  /* Red */

  color: #eb5757;
}

.allticket-requester-text1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  /* Gray 2 */

  color: #4f4f4f;
}

.allticket-requester-text2 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* or 129% */

  /* Gray 2 */

  color: #4f4f4f;
}

.ReactTable {
  border-top-left-radius: 0px !important;

  .rt-thead.-header {
    border-top-left-radius: 0px !important;
    background-color: rgba(200, 202, 216, 0.02) !important;
  }
}

.filterbtn-ticket {
  background-color: white !important;
  color: #1a355e !important;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.336) !important;
}

.filterbtn-ticket:hover {
  box-shadow: 0px 8px 10px rgba(128, 128, 128, 0.336) !important;
}

.filterbtn-ticket:active {
  background-color: white !important;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.336) !important;
}

.tabTextColor {
  color: #1a355e !important;
  background-color: #dee0f7 !important;
}

.filterbtn-ticket:focus {
  background-color: white !important;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.336) !important;
}

.exportbtn-allticket {
  background-color: $secondary !important;
  border: $secondary !important;
  color: white !important;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.336) !important;
}

.exportbtn-allticket:hover {
  box-shadow: 0px 8px 10px rgba(128, 128, 128, 0.336) !important;
  border: $secondary !important;
}

.exportbtn-allticket:active {
  background-color: $secondary !important;
  border: $secondary !important;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.336) !important;
}

.exportbtn-allticket:focus {
  background-color: $secondary !important;
  border: $secondary !important;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.336) !important;
}

[dir] .dropdown-toggle::after {
  background-size: 12px !important;
}

.actionBtn-allTicket {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  // font-size: 15px;

  background-size: 12px !important;
  margin-bottom: 0.2rem;
  background-color: #8bcc6f !important;
  border: 1px solid #8bcc6f !important;
  color: white !important;
  box-shadow: none !important;

  padding: 6px 14px;
  font-size: 0.7rem;
  display: inline-block;
  color: #888888;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: inherit;

  // padding: 6px 14px;
  &:active,
  &:focus {
    background-color: #8bcc6f !important;
  }

  &::after {
    display: none;
  }
}

.actionBtn-createTicket {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  // font-size: 15px;

  background-size: 12px !important;
  margin-bottom: 0.2rem;
  background-color: transparent !important;
  border: 1px solid #1a355e !important;
  color: #1a355e !important;
  box-shadow: none !important;

  padding: 10px 40px;
  font-size: 14px;
  display: inline-block;
  // color: #888888;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: inherit;
  height: 100%;

  // padding: 6px 14px;
  &:active,
  &:focus {
    background-color: transparent !important;
    padding: 10px 40px;
  }

  &::after {
    display: none;
  }
}

[dir] .dropdown-toggle::after {
  background-image: url("/assets/images/pages/actiondrop.png") !important;
}

.main-menu.menu-light .navigation>li ul .active {
  // border-right: 5px solid $secondary !important;
}

.main-menu.menu-light .navigation>li.active>a {
  // border-right: 5px solid $secondary !important;
}

.main-menu.menu-light .navigation>li>a {
  // color: white !important;
}

.main-menu.menu-light .navigation>li ul li>a {
  // color: white !important;
}

.btnFilter {
  width: 8.8rem;
}

.allTickets {
  &__navbar {
    margin-left: 0px;
    margin-bottom: 0;
    min-width: 320px;
    padding-left: 0.74rem;
    // background-color: orange;
    overflow: auto;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none !important;
    }

    // overflow: scroll;
    // display: flex;
    // width: 500px;
    // flex-direction: row;
    // justify-content: flex-start;
    // align-items: center;
    &--item {
      .nav-link {
        padding: 0;

        &::after {
          content: "";
          position: relative;
          width: 0px;
          height: 0px;
        }
      }

      width: auto;
      display: block;
      // float: right;
      padding: 10px;
      background-color: #f2f3fd;
      margin-right: 25px;
      z-index: 1;
      position: relative;
      cursor: pointer;
      color: #777;
      text-transform: uppercase;
      font: 600 13px/20px roboto,
      "Open Sans",
      Helvetica,
      sans-serif;
      transition: all 250ms ease;
      /*   border: 1px solid #D0D3E8; */
      /*   border-top: 0.5px solid #D0D3E8; */
      border-top: 0.5px solid #d0d3e8;
      // border-bottom: 0.5px solid #d0d3e8;
      border-top-right-radius: 10px;

      &::before,
      &::after {
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        height: 100%;
        width: 44px;
        background-color: #f2f3fd;
        transition: all 250ms ease;
        border-top: 1px solid #d0d3e8;
        margin-top: -0.5px;
        z-index: 0;
      }

      &::before {
        right: -15px;
        transform: skew(30deg, 0deg);
        border-top-right-radius: 10px;
        border-right: 1px solid #d0d3e8;
        width: 30px;
        // z-index: 1;
        // border: 1px solid green;
      }

      &::after {
        border-left: 1px solid #d0d3e8;
        border-top-left-radius: 5px;
        left: -10px;
        // z-index: 0 !important;
        width: 10px;
        // border: 1px solid green;
      }

      &.active {
        background: #fff;

        &::before,
        &::after {
          background: #fff;
          z-index: 9999 !important;
        }
      }

      &--text {
        min-width: 65px;
        margin: 0;
        // background-color: cornflowerblue;
      }
    }
  }
}

.allTab {
  &__navbar {
    margin-left: 0px;
    margin-bottom: 0;
    min-width: 320px;
    padding-left: 0.74rem;
    overflow: auto;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none !important;
    }

    &--item {
      .nav-link {
        padding: 0;
        font-family: Poppins;

        &::after {
          content: "";
          position: relative;
          width: 0px;
          height: 0px;
        }
      }

      width: 180px;
      display: block;
      // float: right;
      padding: 12px;
      background: $primary;
      margin-right: 25px;
      // z-index: 1;
      position: relative;
      cursor: pointer;
      border-top-left-radius: 5px;
      color: #fff;
      // text-transform: uppercase;
      // font: 600 13px/20px roboto, "Open Sans", Helvetica, sans-serif;
      transition: all 250ms ease;
      border-top: 0.5px solid $primary;
      text-align: center;
      border-top-right-radius: 10px;

      &::before,
      &::after {
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        height: 100%;
        // width: 100px;
        transition: all 250ms ease;
        border-top: 1px solid $primary;
        margin-top: -0.5px;
      }

      &::before {
        right: -15px;
        transform: skew(30deg, 0deg);
        border-top-right-radius: 10px;
        border-right: 1px solid #1a355ecc;
        background: $primary;
        width: 30px;
        // padding-bottom: 39px;
      }

      // &::after {
      //   border-left: 1px solid $primary;
      //   border-top-left-radius: 5px;
      //   // left: -10px;
      //   width: 10px;
      //   height: 40px;
      //   // padding-bottom: 40px;
      //   background: $primary;
      // }
      &.active {
        background: $primary;

        &::before,
        &::after {
          background: $primary;
        }
      }

      &--text {
        min-width: 65px;
        margin: 0;
        // background-color: cornflowerblue;
      }
    }
  }
}

.rs-picker-toggle-active {
  border-color: red !important;
}

.customChart {
  .apexcharts-legend {
    top: 5px !important;

    // display: flex;
    // flex-direction: row !important;
    span {
      border-radius: 12px !important;
    }
  }
}

.custom-legend {
  .apexcharts-legend {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .apexcharts-legend-series {
    padding: 5px;
    font-size: 30px;
    text-align: center;
  }
}

.customDatePicker {
  background: #ffffff;
  border: 1px solid #2a347b;
  border-radius: 5px;
  display: flex;
  width: 20.5rem;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;

  .flatpickr-input {
    border: none;
    background-color: none !important;
  }

  .arrow-end {
    margin-left: 5rem;
    display: flex;
    justify-content: end;
  }

  .rs-picker-toggle-textbox {
    padding-right: 0px !important;
  }

  .active {
    background-color: none !important;
    box-shadow: none;
  }

  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #171b1e;
    margin-left: 1rem;
  }
}

.QualityBorder {
  border-left: 7px solid $secondary;
  height: 135px;
  border-radius: 10px;
  margin-right: 0.2rem;
}

.C_RateBorder {
  border-left: 7px solid $secondary;
  height: 135px;
  border-radius: 10px;
  margin-right: 0.2rem;
}

.C_TATBorder {
  border-left: 7px solid $secondary;
  height: 135px;
  border-radius: 10px;
  margin-right: 0.2rem;
}

.Res_TATBorder {
  border-left: 7px solid $secondary;
  height: 135px;
  border-radius: 10px;
  margin-right: 0.2rem;
}

.customTables {
  .ReactTable {
    border-top: none;
    border-radius: 5px;

    .rt-thead {
      &.-header {
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
        background-color: $primary;
      }

      .rt-tr {
        // padding-top: 10px;
        // padding-bottom: 10px;
        color: #fff !important;
        background-color: $primary !important;
      }

      .rt-th {
        box-shadow: none;
        background-color: $primary;
        font-style: normal;
        font-size: 13px !important;
        font-weight: 300 !important;
        color: #fff !important;

        line-height: 21px;
      }

      .rt-resizable-header {
        width: 0px !important;
      }
    }
  }

  .rt-tbody {
    text-align: center;

    .rt-td {
      padding: 0.5rem;
      margin-top: 0rem !important;
    }

    .rt-tr-group {
      .rt-tr {
        background-color: rgba(128, 128, 128, 0.37);

        .rt-td {
          width: 0;

          border: 2px solid $white;
        }
      }
    }
  }
}

//  .ReactTable .rt-thead .rt-th {
//   background-color: rgba(208, 211, 232, 0) !important;
//   font-family: Poppins;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
//   /* identical to box height */

//   text-transform: uppercase;
//   margin-top: 10px;
//   border: 0 !important;

//   /* Gray 3 */

//   color: #828282;
// }
//  .ReactTable .rt-thead .rt-tr {
//   background-color: white !important;
//   padding: 0 !important;
//   box-shadow: 0 !important;
//   border-bottom: 1px;
// }
.fixedBottom {
  position: absolute;
  bottom: 0;
}

.countFont {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.countGraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-transform: capitalize;

  color: #fff;
}

.powered-By {
  position: absolute;
  bottom: 30px;
}

.Register-By {
  position: absolute;
  bottom: 2rem;
}

@media screen and (max-width: 390px) {
  .Register-By {
    bottom: 5px;
  }
}

.NotiBg {
  background-color: #cfe3e4 !important;
}

.notifications__sideBar__notificationContainer__main__body--subTitle {
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
}

.actionticket {
  background-color: black !important;
}

.marquee {
  display: flex;
  align-items: center;
  padding-right: 1rem;

  &__heading {
    display: flex;
    align-items: center;
    // background: $secondary;
    color: white;
    padding: 1rem;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &--text {
      color: #4f4f4f;
    }

    &--icon {
      color: $secondary;
    }

    &--text {
      margin-bottom: 0;
      margin-left: 0.5rem;
    }
  }
}

//light-purple
.badge-light-purple {
  color: #7878f1 !important;
  background-color: #7878f142 !important;
}

//light returnTo
.badge-light-returnTo {
  color: $secondary !important;
  background-color: #d5e6ed !important;
}

// -- Tour-Start -- //

.filter-model {
  top: 200px !important;
}

.user-model {
  color: #060122;
}

.modal-backdrop {
  padding: 0 !important;
}

// .filter-model {
//   .modal-content {
//     border-radius: 16px;
//   }
// }
.body-M {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thumb-image {
  width: 30%;
  height: 30%;
  margin-bottom: 10px;
}

.model-text {
  text-align: center;
  padding: 10px 50px;
}

.modal-btn {
  margin-top: 10px;
  background: #012c6b !important;
  border-radius: 8px !important;
  padding: 10px 50px !important;
}

.modal-btn:hover {
  background: #011738 !important;
}

.to-no {
  color: #011738;
}

.to-no--pointer {
  // margin-top: 30px;
  // color: rgba(21, 25, 32, 0.5);
  cursor: pointer;
  color: #011738;
}

.to-no--pointer:hover {
  color: #012c6b;
}

.take-survey {
  color: white !important;
  // text-decoration: none !important;
}


.supportOptions {
  margin-top: auto;
  padding: 0px 15px;
}

.verticalMenuHeader {
  padding: 15px 15px;
  // margin-bottom: 15px;

  .logo-container {
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E2E4E9;

  }
}

//  -- Tour-End -- //'
.verticalMenuFooter {
  padding: 0px 15px;


  .NHCC-select {
    border-top: 1px solid #E2E4E9;
    padding: 0px 5px;
    transition: all .35s ease-in-out;

    .select__control {
      height: 78px;
      padding-left: 52px;
      padding-right: 0px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      border-radius: 0px;
      border: none;

      &--is-disabled {
        background-color: transparent;

        .select__value-container {
          display: none;
        }
      }

      .select__indicators {
        order: 1;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        .select__dropdown-indicator {
          order: 1;
          padding: 0;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          margin-right: 3px;

          span {
            font-family: "Inter", sans-serif;
            font-weight: 500;
            font-size: 11px;
            line-height: 1;
            vertical-align: bottom;
            text-transform: uppercase;
            color: #525866;
          }

          .app-icon {
            transform: rotate(90deg);
            position: relative;
            right: -20px;
            top: 15px;

            svg {
              width: 20px;
              height: 20px;
              fill: #525866
            }
          }
        }

        .select__loading-indicator {
          order: 2;
          padding: 0px;
        }
      }

      .select__value-container {
        order: 2;
        padding: 0;
      }

      .select__value-container,
      .select__single-value,
      .select__value-container--has-value,
      .css-hlgwow {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0A0D14;
        padding: 0;
        margin: 0;
      }

      &::before {
        content: " ";
        position: absolute;
        width: 40px;
        height: 40px;
        background-image: url("/assets/images/icons/organizationBuilding.svg");
        background-size: cover;
        border-radius: 50%;
        top: 20px;
        left: 0px;

      }
    }

    .select__menu {
      z-index: 5;
      width: 200px;
      overflow: hidden;
      border-radius: 16px !important;
    }

  }

  &.menu-collapsed {
    .NHCC-select {

      .select__control {
        padding-left: 0px;
      }

      .select__value-container,
      .select__indicators {
        opacity: 0;

      }
    }
  }

}


.Action_Ticket {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  margin-bottom: 1.5rem;
}


.basic-selection {
  .select__control {
    padding: 0;
    border-radius: 4px;
  }
}

.hideSvg {
  .select__indicator {
    svg {
      display: none;
    }
  }

  .select__multi-value__remove {
    svg {
      display: none;
    }
  }
}

@media screen and (max-width: 844px) {
  .bookmark-wrapper {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .smallScreen {
    margin-left: 3.2rem !important;
  }
}

// .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
//   background: #536994 !important;
//   box-shadow: none;
// }

//custom bar chart
.customChart {
  height: 650px !important;
  overflow: scroll;
}

.customChartCount {
  height: 250px !important;
  overflow: scroll;
}

.customGraph {
  overflow: inherit;
}

.comment-content {
  margin-left: 2.3rem;
}

.CustomDelete {
  margin-top: 1.9rem;
}

@media screen and (max-width: 767px) {
  .CustomDelete {
    margin-bottom: 0.5rem;
    margin-top: 0rem;
  }
}

.maintenance-page {
  margin-top: 250px;
}

.show-icons {
  margin-right: 7px;
}

.priority {
  display: flex;
  justify-content: end;
}

.low {
  color: #16b455;
  font-size: 16px;
  border: 2px solid #16b455;
  border-radius: 5px;
  text-transform: capitalize;
  height: 35px !important;

  width: auto !important;
}

.medium {
  color: #0447a8;
  height: 37px !important;
  border: 2px solid #0447a8;
  border-radius: 5px;
  font-size: 16px;
  text-transform: capitalize;
}

.high {
  color: #c11715;
  border: 2px solid #c11715;
  border-radius: 5px;
  font-size: 16px;
  text-transform: capitalize;
  height: 35px !important;
  // position: absolute !important;
}

.critical {
  color: #f21b19;
  border-color: #f21b19;
  border: 2px solid #c11715;
  border-radius: 5px;
  font-size: 16px;
  text-transform: capitalize;
  height: 35px !important;
}

.rotate-menuexpand {
  transform: rotate(180deg);
  // transform-origin: top left;
}

.suport-icon {
  margin-right: 3px;
}

.info-icon {
  margin-left: 5px;
  color: red;
}

.globe {
  // width: 30px;
  margin-bottom: 0.4rem;
  margin-right: 0.5rem;
}

.breadcrumb-item {
  transform: rotate(0) !important;
}

.customNav {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.customBorder {
  border-right: 1px solid gray;
  border-radius: 0px;
}

@media screen and (max-width: 646px) {
  .customNav {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 575px) {
  .customNav {
    margin-right: 0.2;
  }
}

//email more
.chip {
  // background-color: #000 !important;
  // line-height: 0px !important;
  // margin: 15px;
  margin-top: 10px;
  height: 50px !important;
  width: 100%;
  padding-left: 10px !important;
  padding-right: 10px !important;
  // font-size: 0px !important;
  border-radius: 5px !important;
  margin-bottom: 7px;
  padding-top: 0px !important;
  padding-right: 5px !important;
  border-radius: 5px !important;
  font-size: 12px !important;
}

.more-label {
  padding-left: 5px !important;
  color: $primary !important;
  background-color: #f3f3f3;
  padding-right: 5px;
  border-radius: 5px;
  float: right;
  // padding-top: 7px;
}

.less-label {
  color: red;
  float: right;
  background-color: #f3f3f3;
  padding-right: 5px;
  border-radius: 5px;
  padding-left: 5px !important;
}

.modelCustom {
  // left: 0;
  // margin-left: 14%;
}

.modal-dialog {
  margin-bottom: 0%;
}

.customPointer {
  cursor: pointer;
  border-bottom: 2px solid $primary;
}

.cursorPointer {
  cursor: pointer;
}

.customGary {
  color: #333333;
}

.uploader-bg {
  background-color: #f2f3fd;
  border-radius: 30px;
  border: 1px solid rgb(226, 225, 225);
  padding: 0;
}

.login-btn-color {
  color: $secondary;
}

.customMargin {
  margin-top: 0.5rem;
}

.customMarginBottom {
  margin-bottom: 0.5rem;
}

.-breadcrumb-item:before {
  transform: rotate(-180deg);
  background-color: #060122;
}

.login-or {
  color: #000;
}

.customSelect {
  .form-control {
    background-color: white !important;
  }
}

.hr-login {
  width: 2%;
  border-top: 1px solid black;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.pdf-text {
  font-weight: normal;
  font-size: 15px;
  color: #000;
  margin-top: 5px;
}

.file-div {
  width: 120px;
}

.drop-up .Select-menu-outer {
  top: auto;
  bottom: 100%;
}

.email-check {
  z-index: 0;
}

.ex1 {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.Toastify__toast--error {
  background-color: $primary !important;
  background-image: url("/assets/images/logo/ToastImage.png") !important;
  background-repeat: no-repeat !important;
  height: 75px;
  padding: 10px !important;
}

.Toastify__toast--success {
  background-image: url("/assets/images/logo/ToastImage.png") !important;
  background-color: $primary !important;
  background-repeat: no-repeat !important;
  height: 75px;
  padding: 10px !important;
}

.Toastify__toast-container {
  .Toastify__progress-bar {
    // display: none !important;
  }

  .Toastify__toast-body {
    // color: $white !important;

    .Toastify__close-button {
      svg {
        path {
          fill: white !important;
        }
      }
    }
  }
}

.Toastify__toast .Toastify__close-button svg {
  fill: white !important;
}

//remove arrow type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//time picker custom design
.react-time-picker__wrapper {
  border: 1px solid rgb(201, 199, 199) !important;
  border-radius: 5px;
  padding: 5px;
}

.react-time-picker__inputGroup__hour {
  color: rgb(133, 130, 130) !important;
}

.react-time-picker__inputGroup__minute {
  color: rgb(133, 130, 130) !important;
}

//for firfox
input[type="number"] {
  -moz-appearance: textfield;
}

.marquee-container {
  overflow: hidden;
}

.customCheck {
  margin-left: 0.1rem;
}

.create-profile {
  position: absolute;
  width: auto;
  right: 1%;
  top: 1%;
  z-index: 1000;
  border-radius: 15px;
}

.register {
  position: absolute;
  width: auto;
  right: 1%;
  top: 1%;
  z-index: 1000;
  border-radius: 15px;
}

// -- Sloved Tickets Dashboard -- // End

.emailbot {
  font-size: 12px;
  font-weight: 500;
}

.timePicker {
  .flatpickr-input {
    padding: 7px;
    border-radius: 5px;
    border: #999 1px solid !important;
  }
}

.bg-color {
  background-color: #eb5757;
}

.YellowColor {
  color: #f9c70c;
}

.viewBorder {
  border-bottom: 2px solid;
}

.create-field-slect-1 {
  // z-index: 4000;
}

.create-field-slect-2 {
  // z-index: 3000;
}

.create-field-slect-3 {
  // z-index: 2000;
}

.create-field-slect-4 {
  // z-index: 1000;
}

.user-name {
  // font-size: 0.5rem;
  margin: 0 !important;
  font-size: 15px;
  font-style: normal;

  font-weight: 500;
}

.user-role {
  // font-size: 0.5rem;
  margin: 0 !important;
  font-size: 15px;
  font-style: normal;

  font-weight: 500;
}

// -- Chat Box -- // Start

.chatbot {
  position: absolute;
  bottom: 1rem;
  padding: 0 1rem;
  z-index: 9000;
  width: 100%;
}

// .chatbox-image-padding {
//   padding: 60px;
// }
// .chatbot {
//   position: absolute;
//   bottom: 0;
//   margin-bottom: 30px;
//   padding-left: 5px;
//   padding-right: 5px;
//   // z-index: 9000;
// }
.react-tags__search-input {
  width: 100% !important;
  padding: 10 !important;
}

.need-help {
  color: white;
  // z-index: 9000;
}

.span-help {
  width: 300px;
}

.help-box {
  // margin-left: 35px;
  padding-bottom: 10px;
  border-top: 0.2px solid #6e71917c;
  position: fixed;
  // padding: 30px;
  bottom: 0;
  // margin-top: 100px;
  background-color: $primary;

  // cursor: pointer !important;
  &--icon {
    cursor: pointer !important;
  }
}

// .chatbox-button {
//   position: absolute;
//   bottom: 0;
//   margin-left: 125px;
//   margin-bottom: 45px;
//   // z-index: 9000;
// }
// .chatbox-button-icon {
//   margin-right: 5px;
//   // z-index: 9000;
// }
// -- Chat Box -- // End
.listing-custom-checkboxes {
  width: 64px;

  .regular-checkbox {
    display: none;
  }

  .regular-checkbox+label {
    background-color: transparent;
    border: 3px solid #cacece;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
  }

  .regular-checkbox+label:active,
  .regular-checkbox:checked+label:active {
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    //   inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    background-color: $primary;
    // border: 3px solid #cacece;
  }

  .regular-checkbox:checked+label {
    background-color: $primary;
    border: 3px solid $primary;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    //   inset 0px -15px 10px -12px rgba(255, 255, 255, 0.3),
    //   inset 15px 10px -12px rgba(255, 255, 255, 0.3);
    // color: #fff;
  }

  .regular-checkbox:checked+label:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 2px;
    color: #fff;
  }
}

.listing-custom-checkboxes1 {
  .regular-checkbox1 {
    display: none;
  }

  .regular-checkbox1+label {
    background-color: transparent;
    border: 3px solid #cacece;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
  }

  .regular-checkbox1+label:active,
  .regular-checkbox1:checked+label:active {
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    //   inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    background-color: $primary;
    // border: 3px solid #cacece;
  }

  .regular-checkbox1:checked+label {
    background-color: $primary;
    border: 3px solid $primary;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    //   inset 0px -15px 10px -12px rgba(255, 255, 255, 0.3),
    //   inset 15px 10px -12px rgba(255, 255, 255, 0.3);
    // color: #fff;
  }

  .regular-checkbox1:checked+label:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 2px;
    color: #fff;
  }
}

.Custom-hash-tag {
  background-color: whitesmoke;
  color: rgb(70, 62, 62);
  padding: 5px;
  font-size: 10px;
  border-radius: 5px;
  border: 1px solid whitesmoke;
}

.custom {
  margin-top: 0.5rem;
}

.S-Button {
  position: fixed;
  left: 10;
  border-color: #aaa8a8;
  border: 0;
  border-radius: 0;
}

// --  NHCC Login page -- Start
.NHCC-head {
  font-weight: bolder;
  font-size: 35px;
}

.pra2-login {
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.pra1-login {
  font-size: 22px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.image-help {
  position: fixed;
  bottom: 0;
  width: 25%;
  height: 50vh;
}

.card-t {
  font-weight: 500;
  font-size: 12px;
}

.forget-pass {
  font-weight: 500;
}

.Acoount-Request {
  font-weight: 500;
  color: $secondary;
}

// .card-header {
//   svg {
//     color: $primary;
//   }
// }
.CustomlogButton {
  font-weight: bolder;
  width: 150px;
  border-radius: 15px;
  padding: 2px;
  color: #6e7191;
  position: fixed;
  top: 2%;
  left: 85%;
}

.CustomGrey {
  color: #6e7191;
}

.CardBorder {
  border-radius: 15px;
}

.img-fluid {
  max-width: 70%;
}

// -- NHCC Login page -- End
// -- NHCC Register Page -- Start

.register2-bg {
  background-image: url("/assets/images/pages/log2bg.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-color: #f2f3fc;
}

.CustomRegButton {
  font-weight: bolder;
  width: 150px;
  border-radius: 15px;
  padding: 2px;
  color: #6e7191;
  position: absolute;
  top: 2%;
  left: 85%;
}


//  -- NHCC Navbar Menu -- End
// Align icons position
.main-menu {
  border-right: 1px solid var(--stroke-soft-200, #E2E4E9);

  .navigation li>a>svg,
  .navigation li>a>i,
  .dropdown-menu svg,
  .dropdown-menu i,
  .dropdown-user>a>svg,
  .dropdown-user>a>i,
  .navigation>li>a>svg,
  .navigation>li>a>i {
    float: right;
  }

  .navigation>li ul li>a {
    display: flex;
    align-items: center;
  }

  // background-image: url("/src/assets/images/pages/Bg2-011.svg") !important;
  // background-color: $primary !important;
  background-color: #ffffff !important;

  background-position: top !important;
  background-size: cover !important;
}

// .vertical-layout.vertical-menu-modern {
//   .main-menu {
//     .navigation>li>a {
//       color: var(--text-sub-500, #525866) !important;
//       font-family: "Inter", sans-serif;
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 500;
//       line-height: 20px;
//       /* 142.857% */
//       letter-spacing: -0.084px;
//     }
//   }
// }

// .custom-main-menu {
//   .custom-navigation {
//     background: transparent !important;
//   }
// }
//cutom nhcc
.main {
  // background-image: url("/src/assets/images/pages/Background.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: $primary;
  height: 100vh;
  width: 100%;
  background-color: $primary;
}

.representatives {
  border-radius: 5px;
  padding: 12px;
}

// }
.login {
  // background: radial-gradient(
  //     44.87% 44.87% at 100% 50%,
  //     #170087 0%,
  //     rgba(22, 6, 109, 0.0001) 100%
  //   ),
  //   radial-gradient(100% 100% at 0% 0%, #1b4b9b 0%, #093175 100%);
  // background-image: url("/src/assets/images/pages/bg-transparent.svg");
  // mix-blend-mode: luminosity;
  // opacity: 0.48;
  // background-position: top;
  background-color: $primary;
  // background-size: cover;

  // height: 100vh;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-Chatimage {
  // background: turquoise;
  background-image: url("/assets/images/pages/chatbg.svg");
  height: 100%;
  // background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 15px;
}

.login2 {
  border-radius: 15px;
}

.login2-bg {
  background-image: url("/assets/images/pages/log2bg.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-color: #f2f3fc;
}

.alignCenter {
  text-align: center !important;
}

.HWHoi {
  // background-color: $secondary;
  color: #fff;
}

.customProgress {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  width: 100%;

  .progress {
    border-radius: 0px !important;
    height: 20px !important;
  }

  .progress-bar {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

// .rs-input-group-inside
.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  outline: none !important;
}

.rs-input-group {
  padding: 0px !important;
  border: none !important;

  .rs-input-group,
  .rs-input:focus {
    outline: none !important;
  }

  .rs-input-group-addon {
    display: none;
  }

  .rs-input {
    padding: unset !important;
  }

  .rs-input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

.rs-picker-toggle-wrapper {
  // width: 100% !important;

  svg {
    display: none;
  }

  .rs-btn {
    padding: 0px !important;
    border: none !important;
  }

  .rs-picker-toggle-active {
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .rs-picker-toggle-value {
    color: #000 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    font-family: Poppins !important;
  }
}

.Custom-percentWidth {
  .progress-bar {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    min-width: 25% !important;
  }
}

.tableGraph {
  .HWHoi {
    background-color: none !important;
  }

  .rdt_TableCol {
    display: flex;
    justify-content: center;
  }
}

.fRgRSO {
  background-color: $secondary;
}

.jHsXUi {
  color: #fff;
}

.Black {
  color: #000;
}

.bgTransparent {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.Input {
  font-weight: 500;
  border-radius: 15px;
  background-color: #fff;
  padding: 2.2rem;
}

.CustomRadius {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.CustomSelect {
  color: rgb(15, 15, 15);
}

.CustomButton {
  font-weight: bolder;
  border-radius: 15px;
  padding: 1rem;
  color: #6e7191;
}

.customFileUpload {
  .uppy-DragDrop-container {
    border: 2px dotted $secondary;
    background: #fff;
    color: inherit;
    border-radius: 5px;
    padding: 4rem;

    svg {
      fill: $secondary;
    }

    &:hover {
      border: 2px dotted $secondary;
    }
  }
}

//table
.table-Data {
  width: 100%;
  color: #6e7191;
}

.table-DueData {
  span {
    width: 100%;
    height: 50%;
    // color: #eb5757;
  }

  p {
    color: #6e7191;
  }
}

.customBadge {
  margin-top: 5px;
}

.table-Badge {
  font-size: 15px;

  border-radius: 14px;
  text-transform: capitalize;
}

//end table
.createSpan {
  margin-left: 0.5rem;
}

#float-label {
  display: flex;
  flex-direction: column;
  min-width: 350px;
}

.filterBtn {
  border-radius: 10px;
  padding: 1rem;
  width: 130px;

  color: #6e7191;
  border: 1px solid #6e7191;
  box-sizing: border-box;
}

.StepBtn {
  border-radius: 10px;
  padding: 1rem;
  width: 200px;
  color: #6e7191;
  border: 1px solid #6e7191;
  box-sizing: border-box;
}

.selector {
  .select__control {
    padding: 0;
    border-radius: 5px;
  }
}

.auth-wrapper .bgImage {
  background-color: rgb(27, 17, 17) !important;
}

#float-label input {
  width: 100%;
  height: 56px;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.Attachment {
  border: 2px $secondary dotted;
  padding: 1.5rem;
}

#float-label label {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
}

.signbt {
  color: aqua !important;
}

// Transformed menu icons
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub>a:after,
.vertical-layout.vertical-overlay-menu.menu-open .main-menu .navigation li.has-sub>a:after {
  transform: rotate(180deg);
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub.open:not(.menu-item-closing)>a:after {
  transform: rotate(90deg);
}

// Horizontal menu
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-toggle::after {
  background-image: url(str-replace(
 str-replace($chevron-left, "currentColor", $body-color),
    "#",
    "%23"
    ));
}

// Dropdown RTL Changes

.header-navbar .navbar-container ul.nav li.dropdown {
  .dropdown-menu {
    top: 41px !important;

    &::before {
      top: 1px;
    }
  }
}

.header-navbar {

  .dropdown,
  .dropup {
    .dropdown-menu.dropdown-menu-right::before {
      right: auto;
      left: 0.5rem;
    }
  }
}

.dropdown,
.dropup,
.btn-group {
  .dropdown-menu {
    right: 0 !important;
    // top: 0 !important;
    // left: 0 !important;

    &.dropdown-menu-right {
      left: 0 !important;

      &::before {
        right: 0.6rem;
        left: auto;
      }
    }
  }
}

.dropleft {
  .dropdown-toggle {
    &::before {
      background-image: url(str-replace(
 str-replace($chevron-right, "currentColor", $white),
        "#",
        "%23"
        )) !important;
    }
  }

  .dropdown-menu {
    left: 0 !important;
    margin-left: 0;
    margin-right: 0.5rem;
  }
}

.dropright {
  .dropdown-toggle {
    &::after {
      background-image: url(str-replace(
 str-replace($chevron-left, "currentColor", $white),
        "#",
        "%23"
        )) !important;
    }
  }

  .dropdown-menu {
    left: 0 !important;
    margin-right: 0;
    margin-left: 0.5rem;
  }
}

// Input Group dropdown
.input-group {
  .dropdown-menu.show {
    top: 0 !important;
    right: auto !important;
    left: 0px !important;
  }
}

// BS Toast
.toast {
  right: 1rem;
}

// Select2
.select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-search__field {
  direction: rtl;
}

// Chartist
.chartjs-render-monitor {
  margin-right: 1rem;
}

// Datatable
div.dataTables_wrapper div.dataTables_filter {
  text-align: left !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before {
  right: 0.45rem;
}

// Avatar group
.avatar-group {

  // Avatar Group Sizings
  .avatar {
    margin-right: -0.785rem;
    margin-left: 0;
  }

  .avatar-sm {
    margin-right: -0.65rem;
  }

  .avatar-lg {
    margin-right: -1.5rem;
  }

  .avatar-xl {
    margin-right: -1.85rem;
  }
}

// Breadcrumb
.breadcrumb:not([class*="breadcrumb-"]),
.breadcrumb.breadcrumb-chevron {
  .breadcrumb-item+.breadcrumb-item {
    &:before {
      // transform: rotate(180deg);
    }
  }
}

// Pagination
.pagination .page-item {

  &.prev-item,
  &.prev,
  &.previous {
    .page-link {
      &:before {
        transform: rotate(180deg);
      }

      &:hover,
      &:active {
        &:before {
          transform: rotate(180deg);
        }
      }
    }

    &.disabled {
      .page-link {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.next-item,
  &.next {
    .page-link {
      &:after {
        transform: rotate(180deg);
      }

      &:hover,
      &:active {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    &.disabled {
      .page-link {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }
}

code[class*="language-"],
pre[class*="language-"] {
  direction: ltr;
}

@media print {

  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

// Calendar
.fc .fc-header-toolbar .fc-right .fc-button.fc-prev-button .fc-icon {
  right: 4px !important;
}

.fc .fc-header-toolbar .fc-right .fc-button.fc-next-button .fc-icon {
  left: -3px !important;
}

// carousel changes
.carousel-control-next {
  left: auto;
  right: 0;
}

.carousel-control-prev {
  left: 0;
  right: auto;
}

// Tooltip
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: $tooltip-bg;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  left: auto;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: $tooltip-bg;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  right: auto;
}

// Popover Style
.popover {
  right: auto !important;
}

// Progress
.progress-bar-animated {
  animation: progress-bar-stripes 40s linear infinite;
}

// Perfect scrollbar RTL fix
body .ps__rail-y {
  right: auto !important;
  left: 1px !important;
}

// FAQ and Pricing page
.faq-navigation img,
.pricing-free-trial .pricing-trial-img {
  transform: scaleX(-1);
}

.feather-chevron-left,
.feather-chevron-right {
  transform: rotate(-180deg) !important;
}

// Kanban
.kanban-application {
  .kanban-container {
    .kanban-item {

      i,
      svg {
        margin-right: 0 !important;
        margin-left: 0.25rem;
      }
    }
  }
}

.ReswithTAT {
  .rateResponse {
    width: 62px;
    height: 62px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background: #dbdbf6;
  }

  .row {
    .svgSecondary {
      svg {
        g {
          path {
            stroke: $secondary !important;
          }
        }

        path {
          stroke: $secondary !important;
        }
      }
    }
  }

  h1 {
    font-weight: 700;
    font-size: 38px;
    color: $primary;

    svg {
      g {
        path {
          fill: $primary;
        }
      }
    }
  }
}

.C_TAT {
  .rateResponse {
    width: 62px;
    height: 62px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background: #f8ebe3;
  }

  h1 {
    font-weight: 700;
    font-size: 38px;
    color: $primary;

    svg path {
      fill: #df0a56;
    }
  }
}

.Rate {
  .rateResponse {
    width: 62px;
    height: 62px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background: #c4eadf;
  }

  h1 {
    font-weight: 700;
    font-size: 38px;
    color: $primary;
  }
}

.Quality {
  .rateResponse {
    width: 62px;
    height: 62px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background: #fae8ed;
  }

  h1 {
    font-weight: 700;
    font-size: 38px;
    color: $primary;
  }
}

.statusCount {
  height: 120px;
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;

  padding-top: 10px;
  text-align: start;
  margin-bottom: 10px;
  // padding-bottom: 10px;
}

.customTopTeam {
  height: 50%;
  text-align: center;
  padding-top: 10px;
}

.bottomCount {
  width: 100%;
  // margin-top: 50px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 20px;
  text-align: start;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    margin-left: 5%;
  }

  .Created {
    color: $primary;
  }

  .New {
    color: $primary;
  }

  .Exclude {
    color: $primary;
  }

  .Overdue {
    color: $primary;
  }

  .Closed {
    color: $primary;
  }

  hr.Created {
    padding: 0;

    margin: 0;
    border: 3px solid $primary;
  }

  hr.New {
    padding: 0;
    margin: 0;
    border: 3px solid $primary;
  }

  hr.Exclude {
    padding: 0;
    margin: 0;
    border: 3px solid $primary;
  }

  hr.Overdue {
    padding: 0;
    margin: 0;
    border: 3px solid $primary;
  }

  hr.Closed {
    padding: 0;
    margin: 0;
    border: 3px solid $primary;
  }
}

.customYellow {
  color: rgb(255, 208, 0);
}

// Invoice List
.invoice-list-wrapper {
  .dataTables_filter {
    input {
      margin-left: 0 !important;
      margin-right: 0.5rem;
    }
  }

  .dropdown .dropdown-menu.dropdown-menu-right {
    left: 2rem !important;
  }
}

html[dir="rtl"] {
  body {
    .apexcharts-xaxis-inversed-texts-g {
      direction: ltr !important;
    }
  }
}

@media screen and (max-width: 397px) {
  .mainD {
    .btn {
      padding: 0px !important;
    }
  }
}

.CustomLanguage {
  font-size: 1.2rem;
  font-weight: 500;
  font-style: normal;
}

// File Manager
.file-manager-application {
  .sidebar-file-manager {
    .sidebar-inner {
      .my-drive .jstree-node.jstree-closed>.jstree-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.customJustify {
  display: flex;
  justify-content: end;
  // margin-top: 10px;
}

// --  NHCC Login page media -- Start
@media screen and (max-width: 823px) {
  .create-profile {
    position: absolute;
    width: auto;
    right: 8%;
    top: 1%;
    z-index: 1000;
  }
}

@media screen and (max-width: 731px) {
  .create-profile {
    position: absolute;
    width: auto;
    right: 8%;
    top: 3%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 640px) {
  .create-profile {
    position: absolute;
    width: auto;
    right: 10%;
    top: 3%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 384px) {
  .customBtnRes {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 568px) {
  .create-profile {
    position: absolute;
    width: auto;
    right: 12%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 375px) {
  .create-profile {
    position: absolute;
    width: auto;
    // right: 17%;
    top: 4%;
    z-index: 1000;
    border-radius: 15px;
  }
}

.CustomPadding {
  padding: 12px;
}

.emailTo {
  margin-left: 3.3rem;
}

@media screen and (max-width: 360px) {
  .create-profile {
    position: absolute;
    width: auto;
    right: 15%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 320px) {
  .create-profile {
    position: absolute;
    width: auto;
    right: 17%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 576px) {
  .smallScreen {
    margin-top: 0.2rem;
  }
}

@media screen and (max-width: 603px) {
  .CardBorder {
    border-radius: 15px;
    margin: 20px;
  }

  .hello-t-support {
    margin-top: 10px;
    padding: 0px;
  }
}

@media screen and (max-width: 402px) {
  .DownloadBtn {
    top: 0.5rem;
  }
}

@media screen and (max-width: 896px) {
  .ActionBtn {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 583px) {
  .btn-icon {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 992px) {
  .customJustify {
    display: flex;
    justify-content: start;
    margin-top: 10;
  }
}

// --  NHCC Login page media -- End
//  -- NHCC Rrgister Page Media -- Start
// @media screen and (max-width: 1085px) {
//   .CustomRegButton {
//     font-weight: bolder;
//     width: 150px;
//     border-radius: 15px;
//     padding: 2px;
//     color: #6e7191;
//     position: absolute;
//     top: 2%;
//     left: 70%;
//   }
// }
@media screen and (max-width: 823px) {
  .register {
    position: absolute;
    width: auto;
    right: 8%;
    top: 1%;
    z-index: 1000;
  }
}

@media screen and (max-width: 731px) {
  .register {
    position: absolute;
    width: auto;
    right: 8%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 640px) {
  .register {
    position: absolute;
    width: auto;
    right: 10%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 511px) {
  .exportbtn-allticket {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 402px) {
  .filterbtn-ticket {
    margin-top: 0.5rem;
  }

  // .exportbtn-allticket {
  //   margin-left: 1.5rem;
  // }
}

@media screen and (max-width: 568px) {
  .register {
    position: absolute;
    width: auto;
    right: 12%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }

  .forget-pass {
    font-weight: 500;
    font-size: 10px;
  }

  .Acoount-Request {
    font-weight: 500;
    color: $secondary;
    font-size: 10px;
  }

  .login-or {
    font-size: 12px;
  }
}

@media screen and (max-width: 540px) {
  .register {
    position: absolute;
    width: auto;
    right: 6%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 411px) {
  .register {
    position: absolute;
    width: auto;
    right: 6%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 375px) {
  .register {
    position: absolute;
    width: auto;
    // right: 17%;
    top: 4%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 360px) {
  .register {
    position: absolute;
    width: auto;
    right: 6%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 320px) {
  .register {
    position: absolute;
    width: auto;
    right: 8%;
    top: 1%;
    z-index: 1000;
    border-radius: 15px;
  }
}

@media screen and (max-width: 400px) {
  .register2-bg {
    background-image: url("/assets/images/pages/log2bg.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 870px;
    background-color: #f2f3fc;
  }

  .heading-req {
    font-size: 22px;
  }

  .coloum {
    width: 100%;
    height: 80%;
    margin-top: 50px;
  }
}

@media screen and (max-width: 280px) {
  .heading-req {
    font-size: 16px;
  }

  .CustomRegButton {
    font-weight: bolder;
    width: 150px;
    border-radius: 15px;
    padding: 2px;
    color: #6e7191;
    position: absolute;
    top: 4%;
    left: 45%;
  }
}

//  -- NHCC Rrgister Page Media -- End
//  -- NHCC Navbar Menu -- Start
@media screen and (max-width: 380px) {
  .create-ticket {
    // font-size: 10px;
    // padding: 10px;
    // margin-right: 130px;
    display: none;
  }

  // .plus-icon {
  //   font-size: 10px;
  // }
  // .IconCustom {
  //   position: absolute;
  //   right: 100%;
  // }
  // .notification-icon {
  //   position: absolute;
  //   right: 20%;
  //   top: 38%;
  // }
  // .msg-icon {
  //   position: absolute;
  //   right: 28%;
  //   top: 38%;
  // }
  // .chatbot {
  //   position: relative;
  //   bottom: 0;
  //   margin-bottom: 5px;
  //   padding-left: 5px;
  //   padding-right: 5px;
  //   .img-fluid {
  //     max-width: 100%;
  //   }
  //   z-index: 9000;
  // }
}

// @media screen and (max-width: 360px) {
//   .create-ticket {
//     font-size: 10px;
//     padding: 10px;
//     margin-right: 130px;
//   }
//   .plus-icon {
//     font-size: 10px;
//   }
//   .notification-icon {
//     position: absolute;
//     right: 30%;
//     top: 38%;
//   }
//   .msg-icon {
//     position: absolute;
//     right: 38%;
//     top: 38%;
//   }
// }
//  -- NHCC Navbar Menu -- End