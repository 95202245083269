/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.badge-container {
  position: relative;

  .noti-badge {
    background-color: red;
    position: absolute;
    top: -10px;
    right: -7px;
  }
}

#fr-logo {
  display: none;
}

.app-listing {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 112px);

  .app-btn {
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
    font-size: 0.7rem;
    box-shadow: none;
    background-color: #fff !important;
    border: 1px solid var(--stroke-soft-200, #E2E4E9) !important;
    box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24) !important;

  }


  .listing-action-buttons {
    // margin-left: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 0px;


    .smart-search-container {
      margin-right: auto;
      display: flex;
      align-items: center;

      .search-input {
        width: 250px;

        .form-control,
        .input-group-append {
          height: 28px;
        }
      }

      .ss-help {
        margin-left: 5px;
      }
    }

    .app-btn {
      margin: 0rem;
      padding: 7px 12px;

      svg {
        width: 18px;
        height: 18px;
      }

      .text {
        color: #525866;
        text-align: center;
        /* Label/Small */
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
      }
    }

  }
}